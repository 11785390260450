<template>
  <div class="authority-list">
    <div class="titles">
      <div>角色</div>
      <div class="authority">权限</div>
      <div>账号数量</div>
      <div>角色创建时间</div>
      <div>操作</div>
    </div>
    <div class="authority-content">
      <template :key="item" v-for="item in authorityList">
        <div class="item">
          <div class="name">{{ item.name }}</div>
          <div class="authority">
            <template v-if="item.type == 1">
              <div>{{ item.authority }}</div>
            </template>
            <template v-else>
              <template></template>
            </template>
          </div>
          <div class="acount-num">{{ item.acountNum }}</div>
          <div class="create-time">{{ item.createTime }}</div>
          <div class="handle">
            <span>停用</span>
            <span>编辑</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    authorityList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.authority-list {
  margin-top: 20px;
  .authority-content {
    padding: 0 10px;
    .item {
      display: flex;
      margin: 30px 0;
      font-size: 14px;
      padding-bottom: 30px;
      border-bottom: 1px solid var(--borderColor);

      div {
        width: 200px;
      }
      .name {
      }
      .authority {
        flex: 1;
      }
      .acount-num {
      }
      .create-time {
      }
      .handle {
        color: rgba(42, 130, 228, 1);
        span {
          margin-right: 10px;
        }
      }
    }
  }
  .titles {
    display: flex;
    height: 40px;
    line-height: 40px;
    background-color: rgba(239, 239, 239, 1);
    font-size: 14px;
    padding: 0 10px;
    div {
      width: 200px;
    }
    .authority {
      flex: 1;
    }
  }
}
</style>




