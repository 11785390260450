<template>
  <div class="top-content">
    <el-button class="add-staff" type="primary" @click="handleAddStaffClick">
      <el-icon>
        <plus />
      </el-icon>新建角色
    </el-button>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.top-content {
}
</style>




