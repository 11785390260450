<template>
  <div class="list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="list-content">
      <top-content></top-content>
      <authority-list :authorityList="authorityInfoList"></authority-list>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import AuthorityList from './cpns/aurthority-list/authority-list.vue'
import { useState } from '@/hooks'
import TopContent from './cpns/top-content/top-content.vue'
export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    AuthorityList,
    TopContent
  },
  setup() {

    const authorityState = useState('authority', ['authorityInfoList'])
    return {
      breadcrumbList,
      ...authorityState
    }

  }
})
</script>

<style scoped lang="less">
.list {
  .list-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




